const query = `
  mutation createContribution(
    $amount: Number,
    $fundraiser: [Int]
    $title: String,
    $authorId: ID,
    $slug:String,  
  ) {
    save_contribution_anonymous_Entry(
      goal: $amount,
      fundraiser: $fundraiser
      title: $title,
      authorId: $authorId,
      slug: $slug,
      siteId: 9
    ) {
      slug
      date: dateCreated
      goal
      fundraiser {
        slug
      }
    }
  }
`.replace(/(\n|\s)/g, " ");

const createContributionPublic = async (formData, dispatch) => {
  try {
    const variables = {
      ...formData,
      authorId: 43274,
      fundraiser: [parseInt(formData.fundraiser, 10)],
    };
    await fetch("/api/mutate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })
      .then(res => res.json())
      .then(res => {
        // console.log("res", res);
        // const c = res.save_contribution_public_Entry;
        // const resolved = {
        //   id: c.slug,
        //   date: c.dateCreated,
        //   amount: c.goal / 100,
        //   name: c.fName || "Anonymous",
        //   fundraiser: c.fundraiser[0]
        //     ? {
        //         title: c.fundraiser[0].title,
        //         slug: c.fundraiser[0].slug,
        //       }
        //     : null,
        // };
        // console.log("resolved Contribution", resolved);
        // if (dispatch) {
        //   dispatch({
        //     type: "pushContribution",
        //     contribution: resolved,
        //   });
        // }
      })
      .catch(e => {
        throw Error(e);
      });
  } catch (e) {
    throw Error(e);
  }
};

export default createContributionPublic;
